const ptBR = {
  btn: {
    accept: 'Aceito',
    add: 'Adicionar',
    archive: 'Arquivar mensagens',
    back: 'Voltar',
    cancel: 'Cancelar',
    cancel_change: 'Cancelar alteração',
    cancelManual: 'Cancelar',
    change: 'Alterar',
    clear: 'Limpar',
    close: 'Fechar',
    confirm: 'Confirmar',
    copy: 'Copiar',
    copyLink: 'Copiar Link',
    delete: 'Excluir',
    edit: 'Editar',
    export_xls: 'Exportar (XLS)',
    filter: 'Filtrar',
    finish: 'Finalizar',
    initAttendance: 'Iniciar Atendimento',
    integrationManual: 'Integração Manual',
    new: 'Novo',
    no: 'Não',
    not_accept: 'Não Aceito',
    notify_participant: 'Notificar participante',
    off: 'Desligado',
    on: 'Ligado',
    performedManual: 'Realizar',
    prescriptionDigital: 'Prescrição digital',
    reactivate: 'Reativar',
    resendMailMedic: 'Reenviar E-mail',
    resendMailPacient: 'Reenviar E-mail',
    restore: 'Restaurar',
    save: 'Salvar',
    send: 'Enviar',
    sendFile: 'Enviar Arquivo',
    share_event: 'Compartilhar Evento',
    unarchive: 'Desarquivar mensagens',
    update_payment: 'Atualizar Pagamento',
    yes: 'Sim',
  },
  country: {
    AD: 'Andorra',
    AE: 'Emirados Árabes Unidos',
    AF: 'Afeganistão',
    AG: 'Antígua e Barbuda',
    AI: 'Anguila',
    AL: 'Albânia',
    AM: 'Armênia',
    AN: 'Antilhas Holandesas',
    AO: 'Angola',
    AQ: 'Antártica',
    AR: 'Argentina',
    AS: 'AmericanSamoa',
    AT: 'Áustria',
    AU: 'Austrália',
    AW: 'Aruba',
    AX: 'Ilhas Aland',
    AZ: 'Azerbaijão',
    BA: 'Bósnia-Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Bélgica',
    BF: 'Burkina Faso',
    BG: 'Bulgária',
    BH: 'Bahrein',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'Saint Barthelemy',
    BM: 'Bermudas',
    BN: 'Brunei Darussalam',
    BO: 'Bolívia, Estado Plurinacional de',
    BR: 'Brasil',
    BS: 'Bahamas',
    BT: 'Butão',
    BW: 'Botsuana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canadá',
    CC: 'Ilhas Cocos (Keeling)',
    CD: 'Congo, República Democrática do Congo',
    CF: 'República Centro-Africana',
    CG: 'Congo',
    CH: 'Suíça',
    CI: 'Costa do Marfim',
    CK: 'Ilhas Cook',
    CL: 'Chile',
    CM: 'Camarões',
    CN: 'China',
    CO: 'Colômbia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cabo Verde',
    CX: 'Ilha de Natal',
    CY: 'Chipre',
    CZ: 'República Tcheca',
    DE: 'Alemanha',
    DJ: 'Djibouti',
    DK: 'Dinamarca',
    DM: 'Dominica',
    DO: 'República Dominicana',
    DZ: 'Argélia',
    EC: 'Equador',
    EE: 'Estônia',
    EG: 'Egito',
    ER: 'Eritréia',
    ES: 'Espanha',
    ET: 'Etiópia',
    FI: 'Finlândia',
    FJ: 'Fiji',
    FK: 'Falkland Islands (Malvinas)',
    FM: 'Micronésia, Estados Federados da Micronésia',
    FO: 'Ilhas Faroe',
    FR: 'França',
    GA: 'Gabão',
    GB: 'Reino Unido',
    GD: 'Grenada',
    GE: 'Geórgia',
    GF: 'Guiana Francesa',
    GG: 'Guernsey',
    GH: 'Gana',
    GI: 'Gibraltar',
    GL: 'Gronelândia',
    GM: 'Gâmbia',
    GN: 'Guiné',
    GP: 'Guadalupe',
    GQ: 'Guiné Equatorial',
    GR: 'Grécia',
    GS: 'Geórgia do Sul e as Ilhas Sandwich do Sul',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guiné-Bissau',
    GY: 'Guiana',
    HK: 'Hong Kong',
    HN: 'Honduras',
    HR: 'Croácia',
    HT: 'Haiti',
    HU: 'Hungria',
    ID: 'Indonésia',
    IE: 'Irlanda',
    IL: 'Israel',
    IM: 'Ilha de Man',
    IN: 'Índia',
    IO: 'Território Britânico do Oceano Índico',
    IQ: 'Iraque',
    IR: 'Irã, República Islâmica do Golfo Pérsico',
    IS: 'Islândia',
    IT: 'Itália',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordânia',
    JP: 'Japão',
    KE: 'Quênia',
    KG: 'Quirguistão',
    KH: 'Camboja',
    KI: 'Kiribati',
    KM: 'Comores',
    KN: 'São Cristóvão e Névis',
    KP: 'Coréia, República Popular Democrática da Coréia',
    KR: 'Coréia, República da Coréia do Sul',
    KW: 'Kuwait',
    KY: 'Ilhas Cayman',
    KZ: 'Cazaquistão',
    LA: 'Laos',
    LB: 'Líbano',
    LC: 'Santa Lúcia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Libéria',
    LS: 'Lesotho',
    LT: 'Lituânia',
    LU: 'Luxemburgo',
    LV: 'Letônia',
    LY: 'Jamahiriya Árabe Líbia',
    MA: 'Marrocos',
    MC: 'Mônaco',
    MD: 'Moldávia',
    ME: 'Montenegro',
    MF: 'São Martinho',
    MG: 'Madagascar',
    MH: 'Ilhas Marshall',
    MK: 'Macedônia',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongólia',
    MO: 'Macau',
    MP: 'Ilhas Marianas do Norte',
    MQ: 'Martinica',
    MR: 'Mauritânia',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldivas',
    MW: 'Malauí',
    MX: 'México',
    MY: 'Malásia',
    MZ: 'Moçambique',
    NA: 'Namíbia',
    NC: 'Nova Caledônia',
    NE: 'Níger',
    NF: 'Ilha Norfolk',
    NG: 'Nigéria',
    NI: 'Nicarágua',
    NL: 'Países Baixos',
    NO: 'Noruega',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'Nova Zelândia',
    OM: 'Omã',
    PA: 'Panamá',
    PE: 'Peru',
    PF: 'Polinésia Francesa',
    PG: 'Papua Nova Guiné',
    PH: 'Filipinas',
    PK: 'Paquistão',
    PL: 'Polônia',
    PM: 'São Pedro e Miquelon',
    PN: 'Pitcairn',
    PR: 'Porto Rico',
    PS: 'Território Palestino, Ocupado',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguai',
    QA: 'Qatar',
    RE: 'Reunião',
    RO: 'Romênia',
    RS: 'Sérvia',
    RU: 'Rússia',
    RW: 'Ruanda',
    SA: 'Arábia Saudita',
    SB: 'Ilhas Salomão',
    SC: 'Seychelles',
    SD: 'Sudão',
    SE: 'Suécia',
    SG: 'Cingapura',
    SH: 'Santa Helena, Ascensão e Tristão da Cunha',
    SI: 'Eslovênia',
    SJ: 'Svalbard e Jan Mayen',
    SK: 'Eslováquia',
    SL: 'Serra Leoa',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somália',
    SR: 'Suriname',
    SS: 'Sul do Sudão',
    ST: 'São Tomé e Príncipe',
    SV: 'El Salvador',
    SY: 'República Árabe da Síria',
    SZ: 'Swazilândia',
    TC: 'Ilhas Turcas e Caicos',
    TD: 'Chade',
    TG: 'Togo',
    TH: 'Tailândia',
    TJ: 'Tajiquistão',
    TK: 'Tokelau',
    TL: 'Timor-Leste',
    TM: 'Turcomenistão',
    TN: 'Tunísia',
    TO: 'Tonga',
    TR: 'Turquia',
    TT: 'Trinidad e Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzânia, República Unida da Tanzânia',
    UA: 'Ucrânia',
    UG: 'Uganda',
    US: 'Estados Unidos',
    UY: 'Uruguai',
    UZ: 'Uzbequistão',
    VA: 'Santa Sé (Estado da Cidade do Vaticano)',
    VC: 'São Vicente e as Granadinas',
    VE: 'Venezuela, República Bolivariana da Venezuela',
    VG: 'Ilhas Virgens - Britânicas',
    VI: 'Ilhas Virgens - Estados Unidos',
    VN: 'Vietnã',
    VU: 'Vanuatu',
    WF: 'Wallis e Futuna',
    WS: 'Samoa',
    YE: 'Iêmen',
    YT: 'Mayotte',
    ZA: 'África do Sul',
    ZM: 'Zâmbia',
    ZW: 'Zimbábue',
  },
  label: {
    accent: 'Cor em destaque?',
    access_to_reports: 'Tem acesso a relatórios?',
    account_token: 'Token da conta',
    actions: 'Ações',
    activeStatus: 'Status ativo',
    add_participant: 'Adicionar Participante',
    add_payment: 'Adicionar Pagamento',
    additional_minute_recorded_allowed: 'Permite Adicional',
    additional_minute_value: 'Valor Adicional',
    additional_minutes_allowed: 'Permite Adicional',
    additional_recorded_minute_value: 'Valor Adicional',
    address: 'Endereço',
    adress: 'Endereço',
    after_payment: 'Após pagamento',
    all_users: 'Enviar para todos',
    allow_access_appointment_before: 'Tempo em minutos para liberação prévia para acessar a sala de consulta',
    allow_access_attendance_data: 'Permitir acessar dados de atendimento',
    allow_emit_nfe: 'Emitir Nota fiscal eletrônica?',
    allow_holder_register_dependents: 'Permitir titular cadastrar dependentes no consultório?',
    allow_holder_register_dependents_clinical_data:
      'Permitir titular configurar acessos a dados clínicos de dependente com login?',
    allow_manager_to_create_user: 'Permitir gestor cadastrar paciente?',
    allow_medical_prescription_virtual: 'Permitir utilizar prescrição digital',
    allow_notify_nps: 'Permitir envio de notificações de NPS?',
    allow_notpaid_attendance: 'Permitir realizar consulta sem pagar?',
    allow_patient_edit_profile: 'Permitir paciente editar seus dados?',
    allow_patient_payment: 'Incluir pagamento no agendamento por paciente?',
    allow_patient_to_register: 'Permitir paciente se cadastrar?',
    allow_professional_edit_profile: 'Permitir profissional editar seus dados?',
    allow_register_dependent_user: 'Permitir cadastrar dependentes?',
    allow_room_exit_without_finish: 'Finalização consulta profissional',
    allow_room_files: 'Permitir arquivos',
    allow_room_notes: 'Permitir Registro de Atendimento',
    allow_room_participant_picture: 'Permitir tirar foto do paciente',
    allow_room_patient_history: 'Exibir historico do paciente',
    allow_room_recording: 'Permitir gravação',
    allow_room_screen_share: 'Permitir compartilhamento de tela',
    allow_room_upshot: 'Permite Informar Desfecho',
    allow_specifying_dependent: 'Permitir especificar limite de cadastro de dependente por paciente?',
    allow_to_create_ehr_events_on_patient: 'Permitir visualizar/adicionar evento no PEP do paciente?',
    allow_to_modify_appointment: 'Permitir editar agendamento com SLA vencido',
    allow_to_use_nps: 'Habilitar NPS?',
    allowed_to_create_appointment_out_of_availability_by_the_clinic:
      'Permitido criar agendamento fora da disponibilidade pela clínica',
    allows_creating_appointments_at_same_time_in_calendar:
      'Permitido criar agendamento com choque de horários no calendário',
    allows_hidden_button_schedule: 'Ocultar o botão de agenda no consultório',
    allows_hidden_professional_name: 'Ocultar nome dos profissionais no agendamento do paciente',
    amount: 'Quantidade',
    amount_paid: 'Valor pago',
    api_key: 'API Key',
    apply_children: 'Aplicar configurações de Fuso Horário e Permissões em Dependentes?',
    appointment_duration: 'Tempo em minutos de duração da consulta padrão, utilizado para agendamento',
    appointment_loading: 'Finalizando o cadastro dos agendamentos, aguarde!',
    appointment_reason: 'PA Agendado',
    appointment_time: 'Tempo Consulta',
    appointment_type: 'Tipo de Agendamento',
    artificial_intelligence: 'Inteligência Artificial',
    artificial_intelligence_send_queue: 'Em qual momento a IA encaminha o paciente para a fila?',
    artificial_moment_probability: 'No momento em que a maior probabilidade das hipóteses for igual ou superior a',
    artificial_patient_question_rounds: 'Após as rodadas de perguntas ao paciente',
    attendace_out_availability: 'Permitido criar agendamento fora da disponibilidade',
    attendance: 'Atendimento',
    attendance_average_minutes: 'Média Minutos Apuração',
    attendance_average_minutes_recorded: 'Média Minutos Gravação',
    attendance_average_minutos: 'Frequência média minutos',
    attendance_average_minutos_recorded: 'Frequência média minutos gravados',
    attendance_counter: 'Exibir cronômetro',
    attendance_counter_behavior: 'Comportamento do cronômetro',
    attendance_counter_behavior_options: {
      progressive: 'Progressivo',
      regressive: 'Regressivo',
      regressiveWithAddtional: 'Regressivo com acréscimos',
    },
    attendance_counter_options: {
      both: 'Ambos',
      no: 'Não',
      patient: 'Paciente',
      professional: 'Profissional',
    },
    attendance_value: 'Valor Consulta',
    background_login_clinica: 'Tela de login da clínica',
    background_login_consultorio: 'Tela de login do consultório',
    bank_data: 'Dados Bancários',
    banners: 'Banners (disponível no site)',
    bill_type: 'Tipo',
    billing: 'Financeiro',
    billing_agencia: 'Agência',
    billing_agencia_dv: 'Dígito verificador da agência',
    billing_bank: 'Banco',
    billing_bank_account_type: 'Tipo da conta',
    billing_bank_code: 'Código do banco',
    billing_conta: 'Número da conta',
    billing_conta_dv: 'Dígito verificador da conta',
    billing_document_number: 'CPF ou CNPJ da conta',
    billing_legal_name: 'Nome completo ou razão social',
    billing_transfer_day: 'Qual dia as transferências automáticas deverão ser feitas',
    billing_transfer_interval: 'Frequência para transferência automática de valores',
    billing_use: 'Utiliza pagamento?',
    billing_use_emergency: 'Utiliza pagamento na fila virtual?',
    bio: 'Biografia',
    birth_date: ' Data de Nascimento',
    blockedStatus: 'Status bloqueado',
    browser: 'Navegador',
    business_contact: 'Contato de negócio',
    callback: 'Callback',
    callback_url_for_query_notifications: 'URL de callback para notificações da consulta',
    cancel_reason: 'Motivo de Cancelamento',
    cancel_time: 'Data / Hora do Cancelamento',
    cell_phone: 'Celular',
    charge_participant: 'Solicitar pagamento ao participante',
    cid10: 'CID10',
    city: 'Cidade',
    closeAttendanceReason: 'Digite o motivo para encerrar o protocolo',
    cnpj: 'CNPJ',
    color_primary: 'Cor primária da aplicação',
    color_top: 'Cor do topo da aplicação',
    comission_type: 'Tipo de comissão',
    comission_value: 'Valor de comissão',
    comments: 'Observações',
    commission: 'Comissão',
    companion_id: 'Acompanhante',
    company_id: 'Empresa',
    complement: 'Complemento',
    connections: 'Detalhes da consulta',
    consultation_time: 'Tempo total de duração da consulta',
    consultation_time_doctor: 'Tempo total do profissional na sala de atendimento',
    consultation_time_patient: 'Tempo total do paciente na sala de atendimento',
    count_days_after_payment: 'Quantidade de dias após o pagamento',
    country_contact: 'Código do país',
    countryname: 'País',
    cpf: 'CPF',
    creation_date: 'Data de criação',
    credit_card: 'Cartão de Crédito',
    crm: 'CRM',
    DadosPessoais: 'Dados Pessoais',
    date: 'Data',
    date_end_creation: 'Data fim de criação',
    date_start_creation: 'Data inicio de criação',
    day: 'Dia',
    days: 'Dias',
    ddi: 'DDI',
    default_payment_value: 'Valor padrão da consulta',
    default_payment_value_emergency: 'Valor padrão na fila virtual',
    default_time: 'Tempo padrão',
    delete: 'Remover',
    deny_access_appointment_after:
      'Tempo em minutos para bloquear o acesso a sala de consulta após seu horário inicial',
    dependants: 'Dependentes',
    dependent_limit: 'Limite de Dependentes',
    description: 'Descrição',
    destination_address: 'Destinatário',
    developer_setup: 'Desenvolvedor',
    display_guideline_for_professional: 'Exibir diretriz para o profissional?',
    display_more_options: 'Exibir Mais Informações',
    doctor: 'Profissional',
    doctor_id: 'Profissional',
    doctor_time: 'Tempo Profissional',
    doctorManager: 'Permitir que o profissional seja usuário administrador da clínica',
    duration: 'Duração:',
    duration_days: 'Dias de Duração',
    duration_minutes: 'minutos',
    edit: 'Editar',
    edit_shortcut: 'Editar atalho',
    ehr: 'Prontuário Eletrônico do Paciente',
    elective: 'Eletiva',
    email: 'E-mail',
    emergency: 'Fila Virtual',
    emergency_queue_start: 'Fila Inicial',
    emergency_service: 'Pronto-Atendimento',
    enabled: 'Habilitado',
    encryption_key: 'Encryption Key',
    end_date: 'Data Término',
    end_date_payment: 'Data Término Pagamento',
    end_date_time: 'Data / Hora Término',
    end_time: 'Hora Término',
    ended_appointment_after_duration:
      'Define se a consulta deverá ser encerrada automaticamente após o tempo máximo decorrido',
    endereco: 'Endereço',
    endpoint: 'Endpoint',
    entered: 'Entrou',
    error_notify_participant: 'Erro ao notificar participante',
    error_prescription: 'Prescrição Indisponível',
    event: 'Evento',
    event_date: 'Data do evento',
    event_description: 'Descrição',
    event_item: 'Arquivo / Exame',
    event_link: 'Link',
    event_name: 'Nome do evento',
    event_notes: 'Notas',
    event_remetent: 'Remetente',
    expiration_date: 'Data de Validade',
    field: 'Campo',
    file: 'Arquivos',
    finance_mail_address: 'E-mail para receber notificações de pagamento.',
    financial: 'Financeiro',
    finish_attendance: 'Finalizar Protocolo',
    finish_professional_protocol: 'Remover o profissional da fila vitual',
    flow_level: 'Tipo de fila',
    form: 'Formulário',
    forms: 'Formulários',
    forms_saved: 'Formulários cadastrados',
    four_day: '4 Dias',
    galax_hash: 'Galax Hash',
    galax_id: 'Galax Id',
    gender: 'Sexo',
    geolocation: 'Geolocalização',
    group: 'Fila',
    groups: 'Filas',
    guideline: 'Diretriz',
    h_before: ' Hora(s) antes',
    history: 'Histórico',
    history_item_type_APPOINTMENT: 'Atendimento',
    history_item_type_ARCHIVE: 'Arquivo',
    history_item_type_DIAGNOSTIC: 'Exame',
    history_item_type_EVENT: 'Evento',
    history_item_type_EXAM: 'Exame',
    history_item_type_LINK: 'Link',
    history_item_type_NOTES: 'Notas',
    history_item_type_PRESCRIPTION: 'Prescrição Digital',
    history_type_event: 'Tipo do Evento:',
    holder_settings: 'Titular / Dependentes',
    hour: 'Horas',
    hours: 'Horas',
    id: 'ID',
    images: 'Imagens',
    in_payment: 'No pagamento',
    initial_attendance_quantity: 'Quantidade inicial Consultas',
    installments: 'Parcelas',
    integration: 'Integração',
    integration_id: 'Id de integração',
    integrationManual: 'Integração Manual',
    integrationManualSubtitle: 'Digite abaixo o CPF do paciente',
    intermediary_account_number: 'Número da conta no intermediador de pagamentos',
    ip: 'IP',
    is_landing: 'Disponível no site ?',
    is_transaction_owner: 'Dono da transação',
    landing_page: 'Página Inicial',
    latitude: 'Latitude',
    left: 'Saiu',
    license_council: 'Conselho',
    limit: 'Limite',
    link: 'Link',
    load_more: 'Carregar mais ...',
    loading: 'Carregando...',
    location_source: 'Origem',
    location_source_COORDINATES: 'Coordenadas',
    location_source_IP: 'IP',
    login: 'Login',
    logo: 'Logo',
    longitude: 'Longitude',
    m_before: ' Minuto(s) antes',
    mail: 'E-mail',
    manage_all_professionals: 'Gerenciar todos os profissionais',
    manage_appointments: 'Faz gestão de Agendamentos?',
    manage_availability: 'Faz gestão de Disponibilidade?',
    manage_emergency: 'Faz gestão de Fila Virtual?',
    manage_professionals: 'Permitir criar/editar/excluir profissionais',
    manage_subscription_plans: 'Faz gestão de planos?',
    manager: 'Gestor',
    max_additional_minute: 'Máximo Adicionais',
    max_additional_recorded_minute: 'Máximo Adicionais',
    max_appointment_duration:
      'Tempo máximo da consulta em minutos, caso o tempo seja atingido a consulta pode ser finalizada',
    max_attendance_minutes: 'Minutos máximos de participação',
    max_register_depedents: 'Limite máximo de dependentes que podem ser cadastrados',
    maximum_credit_and_card_installments: 'Número máximo de parcelas no cartão de crédito',
    medical_assistent: 'Profissional Assistente',
    medical_specialty: 'Especialidade',
    medicines: 'Medicines',
    message_integration_mails: 'E-mails para notificações de avisos de integração',
    message_module_email_address: 'E-mail para notificações de mensagens',
    message_module_enabled: 'Troca de mensagens habilitada?',
    messages: 'Mensagens',
    mini_logo: 'Mini logo',
    minutes: 'Minutos',
    minutes_consultation: 'Consulta Minutos',
    minutes_quantity: 'Quantidade de minutos',
    minutos_value: 'Valor de minutos',
    module_used: 'Criar usuário/login no sistema ao cadastrar profissional/paciente?',
    moment_created_nfe: 'Momento da criação da NFe',
    more_options: 'Mais Opções',
    multiple: 'Múltiplo',
    name: 'Nome',
    namecpf: 'Nome | Cpf',
    neighborhood: 'Bairro',
    new: 'Adicionar',
    new_event: 'Novo Evento',
    new_shortcut: 'Novo atalho',
    no_data_available: 'Não há dados disponíveis',
    notes: 'Notas',
    notification_channel: 'Canal de Notificação',
    notification_emergency_patient: 'Enviar notificação sobre Fila Virtual para paciente?',
    notification_post_appointment: 'Enviar e-mail pós consulta?',
    notification_post_appointment_files: 'Anexar arquivos no email pos consulta?',
    notification_post_appointment_orientation: 'Enviar orientações no email pós consulta?',
    notification_settings: 'Notificação',
    notify_appointment_cancelled_doctor: 'Notificar profissional ao cancelar agendamento?',
    notify_appointment_cancelled_patient: 'Notificar paciente ao cancelar agendamento?',
    notify_appointment_doctor: 'Notificar profissional na criação do agendamento?',
    notify_appointment_patient: 'Notificar paciente na criação do agendamento?',
    notify_service_unavailable: 'Serviço de notificação indisponível no momento',
    notify_user_doctor: 'Notificar profissional criação e edição de usuário?',
    notify_user_patient: 'Notificar paciente criação e edição de usuário?',
    nps_rating: 'Avaliação',
    nps_ratings: 'Avaliações',
    number: 'Número',
    number_of_dependents: 'Quantidade de dependentes que o titular pode cadastrar',
    one_file: 'Arquivo(s)',
    only_reading: 'Apenas leitura',
    open_recurrence: 'Abrir Recorrência',
    order: 'Ordem',
    orientation: 'Orientações ao Paciente',
    other: 'OUTROS',
    other_participant: 'Outros Participantes',
    parent: 'Empresa Pai',
    parent_access_clinical_data: 'Compartilhar dados clínicos com o responsável?',
    parent_id: 'Responsável',
    participant: 'Participante',
    partner_id: 'Partner Id',
    patient: 'Paciente',
    patient_id: 'Paciente',
    patient_time: 'Tempo Paciente',
    patient_time_end: 'Saída Paciente',
    patient_time_start: 'Entrada Paciente',
    patients_line: 'Pacientes na fila',
    payer: 'Pagador',
    payment_attendance: 'Cobrança pelo Atendimento',
    payment_date: 'Data do Pagamento',
    payment_date_time: 'Data do pagamento',
    payment_status: 'Status do Pagamento',
    payment_type: 'Tipo de Cobrança',
    payment_type_accept: {
      credit_card_or_pix: 'Pagamento apenas com Cartão de Credito ou PIX',
      local: 'Pagamento no local',
      no_charge: 'Sem cobrança',
    },
    payment_types_accepted: 'Meios de Pagamentos aceitos no checkout',
    payment_value: 'Valor',
    payment_value_charged: 'Valor Cobrado',
    payment_value_paid: 'Valor Pago',
    pending_payment: 'pagamento pendente',
    period_days: 'Período de vigência em dias',
    permalink: 'Link do Perfil',
    permissions: 'Permissões',
    personaldata: 'Dados Pessoais',
    picture: 'Foto',
    pix: 'Pix',
    place_text: 'Digite o texto aqui...',
    plan: 'Plano',
    plan_id: 'ID do plano',
    plandata: 'Dados do Plano',
    powered_by_dav: 'Desenvolvido com ❤ pelo Doutor ao Vivo',
    preferential: 'Preferencial',
    prescription_data: 'Medicamentos Prescritos',
    prescription_description_generate: 'Prescrição via {0}',
    prescription_include_address_field: 'Endereço na precrição',
    prescription_settings: 'documentos médicos',
    prescription_virtual_include_address: 'Incluir endereço em prescrição digital?',
    probability:'Probabilidade',
    profession: 'Profissão',
    professional: 'Profissional',
    professional_registration: 'Registro Profissional',
    professional_time_end: 'Saída Profissional',
    professional_time_start: 'Entrada Profissional',
    professional_view_patient_history_office:
      'Permitir que o profissional visualize o prontuário eletrônico dos pacientes no consultório',
    professional_view_patient_history_options: {
      all: 'Todos',
      disabled: 'Desabilitado',
      only_from_professional: 'Somente do Profissional',
    },
    protocol: 'Protocolo',
    provider: 'Provedor',
    providers: 'Provedores',
    quantity: 'Quantidade',
    ranges: 'Faixas',
    rating_text: 'Comentários',
    reason: 'Queixa Principal',
    reason_finished: 'Motivo',
    recording_consultation: 'Consulta Gravação',
    recording_time: 'Tempo Gravação',
    recordings: 'Gravações',
    recurrence: 'Recorrência',
    recurrence_description: 'Selecione abaixo o tipo de recorrência e a quantidade em que se repete',
    recurrence_list: 'Lista de recorrências',
    recurrence_note:
      '<strong> Observação: </strong> Se deseja alterar a data do agendamento, remova todas as recorrências listadas !',
    region: 'UF',
    registration: 'Registro',
    registration_state: 'UF do registro',
    remember: 'Lembre-me:',
    remetent: 'Remetente',
    repeat_recurrence: 'Repetir a cada:',
    representative: 'Representante',
    representative_id: 'ID representante',
    reset: 'Enviar nova senha de acesso',
    reset_password_success: 'Nova senha de acesso enviada com sucesso',
    reset_password_unvailable: 'Impossível resetar acesso',
    reset_subtitle: 'Enviar e-mail com nova senha de acesso ao usuário',
    reset_title: 'Resetar Acesso',
    restric_edit_info: 'Agendamento com restrição de modificação por contrato.',
    result_description: 'Resultado',
    role_participant: 'Tipo de Participante',
    room_settings: 'Sala de Atendimento',
    schedule_datetime: 'Data/Hora agendamento',
    schedules: 'Agendamentos',
    scheduling: 'Agendamento',
    scheduling_settings: 'Agendamentos',
    screening_artificial_intelligence: 'Triagem com Inteligência Artificial',
    search: 'Pesquisar',
    select_channels_and_time: 'Selecione os canais e o tempo para envio de lembrete',
    select_notification_channels: 'Selecione os canais desejados',
    select_time: 'Selecione o tempo para envio de lembrete',
    select_time_confirm: 'Selecione o tempo para envio de lembrete de confirmação',
    selectedItemText: 'Texto da mensagem',
    selectLanguage: 'Selecione o idioma',
    self_appointment_referral_only: 'Auto-Agendamento de especialista somente por Encaminhamento',
    self_schedule_with_referral: 'Auto-Agendamento por Encaminhamento?',
    send_notification: 'Enviar Notificação',
    service_queue: 'Fila de Atendimento',
    settings: 'Configurações',
    share_event: 'Compartilhar Evento',
    share_link: 'Compartilhar Link',
    shortcut: 'Atalho',
    show_availability_for_inoffice_professional: 'Exibir disponibilidade para Profissional em consultório?',
    show_availability_for_inoffice_professional_status: {
      allow_edit: 'Permitir Editar',
      allow_ready: 'Permitir Leitura',
      hide: 'Ocultar',
    },
    signup: 'Auto Cadastro',
    simple: 'Simples',
    single_sale_plan_with_periodicity: 'Plano de venda única com periodicidade?',
    situation: 'Situação',
    size: 'Tamanho:',
    sms: 'SMS',
    smu_code: 'Código SMU',
    social_name: 'Nome Social',
    source: 'Canal',
    source_options: {
      clinic: 'Clínica',
      emergency: 'Fila virtual',
      patient: 'Auto agendamento',
    },
    specialty: 'Especialidade',
    split_percentage: 'Percentual de split',
    split_with_professional: 'Fazer split do valor com prefissional',
    start_date: 'Data Início',
    start_date_attendance: 'Data Início (Consulta)',
    start_date_payment: 'Data Início Pagamento',
    start_date_scheduled: 'Data Início (Agendamento)',
    start_date_time: 'Data / Hora Início',
    start_time: 'Hora Início',
    status: 'Status',
    status_appointment: 'Status Agendamento',
    status_payment: 'Status Pagamento',
    status_plan: 'Status do Plano',
    street: 'Logradouro',
    telephone: 'Telefone',
    term_of_use_accept: 'Aceite de Termos de Uso',
    term_use_agree_with: 'Li e concordo com ',
    text_term_use_accept: 'Li e concordo com <a href="{0}" target="_blank" rel="noreferrer nofollow">{1}</a>',
    text_term_use_login:
      'A {0} atualizou os <b rel="noreferrer nofollow">Termos de Uso</b>. Estamos empenhados em proteger a privacidade e segurança dos nossos usuários.',
    theme_settings: 'Tema',
    time: 'Tempo',
    time_course: 'Periodo',
    time_inactivity_to_disconnect: 'Tempo de inatividade máximo da consulta em minutos',
    timeline: 'Linha do Tempo',
    timeToSchedule: 'Horário',
    timezone: 'Fuso Horário',
    title: 'Título',
    to: 'Para',
    type: 'Tipo',
    type_ratings: 'Tipo de Avaliação',
    uhc_id: 'Cartão Nacional de Saúde',
    upload_file: 'Upload de arquivo',
    upload_files: 'Upload de arquivos',
    upshot: 'Desfecho',
    use_date: 'Data de Uso',
    use_financial: 'Utiliza financeiro?',
    use_notification_channel_patient: 'Enviar lembrete de consulta para paciente sobre agendamento?',
    use_notification_channel_professional: 'Enviar lembrete de consulta para profissional sobre agendamento?',
    use_notification_confirm_patient: 'Enviar lembrete de confirmação de consulta para paciente?',
    use_voucher_track: 'Pessoas que usaram esse voucher',
    user_settings: 'Usuário',
    valid_for: 'Válido por {0} dias',
    valid_until: 'Válido até',
    value: 'Valor',
    value_attendance: 'Valor do Atendimento',
    value_discount: 'Valor de desconto',
    view_messages: 'Visualizar Mensagens',
    voucher: 'Voucher utilizado',
    voucher_discount: 'Valor',
    voucher_expire: 'Data de Validade',
    voucher_limit_use: 'Limite de Uso',
    voucher_type: 'Tipo de uso do voucher',
    voucher_type_discount: 'Tipo de Desconto',
    waiting_room_video: 'Id do video da sala de espera',
    week: 'Semana',
    whatsapp: 'WhatsApp',
    who_we_are_picture: 'Quem somos (disponível no site)',
    zip_code: 'CEP',
  },
  message: {
    access_denied: 'Acesso Negado',
    appointment_reason: 'Selecione um tipo de agendamento',
    appointmentDateEnd: 'Data término {0} não pode ser menor que a data de início {1} !',
    appointmentDateStart: 'Data início {0} não pode ser menor que a data atual {1} !',
    appointmentResendMail: 'Deseja reenviar e-mail',
    appointmentTitleEmergency: 'Pronto-Atendimento agendado, paciente {0}.',
    appointmentTitlePAT: 'Atendimento agendado de {0}, paciente {2} e profissional {1}.',
    appointmentTitlePATSM: 'Atendimento agendado, paciente {1} e profissional {0}.',
    appointmentTitleSMD: 'Atendimento agendado de {0}, paciente {2} e profissional {1}.',
    appointmentTitleSMDSM: 'Atendimento agendado, paciente {1} e profissional {0}.',
    confirm_sign_out: 'Realmente deseja sair?',
    confirmCancel: 'Tem certeza que deseja Cancelar essa consulta?',
    confirmCancelTitle: 'Confirme o Cancelamento',
    confirmPerformedManual: 'Tem certeza que deseja marcar essa consulta como Realizar Manualmente?',
    confirmPerformedManualTitle: 'Confirme Realizar Manualmente',
    copySuccess: 'Copiado com sucesso',
    dialog_prescription_error: 'Para prosseguir com a prescrição cadastre seu endereço no seu perfil de profissional',
    dialog_prescription_error_international_address:
      'Não é permitido endereço internacional para gerar uma prescrição. Certifique que o seu endereço está cadastrado no Brasil',
    dialogDeleteRangeText: 'Tem certeza de que deseja excluir esta faixa?',
    dialogDeleteRangeTitle: 'Confirme a exclusão',
    dialogDeleteSelectText: 'Tem certeza de que deseja excluir?',
    dialogFinishProfessionalProtocolTitle: 'Confirme a liberação do profissional da fila virtual',
    dialogFinishProfessionalSelectText:
      'Esta ação é utilizada quando o profissional não está conseguindo retornar para a tela inicial. Tem certeza de que deseja continuar?',
    dialogFinishRangeTitle: 'Confirme a finalização',
    dialogFinishSelectText: 'Este protocolo está com status {0}, tem certeza que deseja encerrá-lo?',
    display_guideline_for_professional_message:
      'Ao ativar esta funcionalidade, você poderá criar diretrizes para serem exibidas na tela do profissional durante o atendimento.',
    duplicate_schedules: 'Horários duplicados no mesmo canal de notificação.',
    duplicated: 'Já existe um registro com esse valor',
    expired_session: 'Sessão encerrada',
    file_size_limit: 'O tamanho limite de cada arquivo para envio é de {0}MB',
    formErrorText: 'Dados inconsistentes.',
    formErrorTitle: 'Erro',
    formSuccessText: 'Foi realizado com sucesso.',
    full_access: 'Acesso total',
    info_appointment_deleted: 'Este agendamento foi excluido em:',
    integrationManualMessageError: 'Não foi encontrado contrato ativo para esse CPF',
    integrationManualMessageSuccess: 'Contrato encontrado e enviado para a integração',
    invalidMail: 'Deve ser um e-mail válido.',
    isBlank: '{0} não pode conter espaços em branco.',
    isDuplicated: '{0} já existe.',
    isInvalid: '{0} está incorreto.',
    isRequired: '{0} é obrigatório.',
    isUnique: '{0} já existente',
    isValid: '{0} é inválido.',
    late: 'Atrasado',
    maxLength: '{0} deve ter no máximo {1} caracteres.',
    maxValue: '{0} deve ser menor que {1}.',
    minLength: '{0} deve ter pelo menos {1} caracteres.',
    minValue: '{0} deve ser maior que {1}.',
    my_profile: 'Meu Perfil',
    new_event_subtitle: 'Selecione uma opção abaixo e envie arquivos/exames ou links',
    no: 'Não',
    no_access: 'Sem acesso',
    noData: 'Sem dados disponíveis',
    notification_reminders_fields_mandatory: 'Todos os campos para envio de notificação de lembrete são obrigatórios.',
    on_time: 'No horário',
    payment_failure: 'Pagamento não foi aceito, verifique se os dados informados estão corretos',
    payment_in_analysis: 'Pagamento em análise. Notificaremos quando for aprovado',
    payment_not_confirmed:
      'Pagamento não encontrado. Por favor, certifique que a transação com seu banco ocorreu com sucesso',
    payment_success: 'Pagamento Realizado com sucesso',
    preferential_prescription_message: 'Em caso de escolha de múltiplos providers, a opção selecionada como preferencial será demonstrada ao profissional como preferencial.',
    prescription_error: 'Erro ao abrir prescrição. Por favor, tente novamente.',
    recurrenceChangeDate: '{0} não pode ser menor que a Data Início: {1} !',
    saving_prescription: 'Estamos salvando essa prescrição nos arquivos da sala... Aguarde.',
    show_all_appointments: 'Profissional cria/visualiza atendimento de qualquer profissional',
    show_all_consultation: 'Profissional visualiza qualquer consulta de qualquer profissional',
    show_only_owner_appointments: 'Profissional cria/visualiza somente os agendamentos dele',
    show_only_owner_consultation: 'Profissional visualiza somente os que atendeu',
    sign_out: 'Sair',
    special_characters_are_not_allowed: 'Caracteres especiais não são permitidos.',
    successfully_changed: 'Alterado com sucesso.',
    successfully_created: 'Criado com sucesso.',
    successfully_deleted: 'Excluído com sucesso.',
    termPendingAccept: 'Termos de uso do sistema pendente de leitura e de aceite.',
    time_expired_session: 'Sua sessão excedeu o tempo limite',
    unallowed_access_to_page: 'Você não tem permissão para acessar esta página',
    upload_at_least_1_file: 'Faça upload de pelo menos 1 arquivo',
    validate_error: 'Erro de validação! Por favor verifique os campos preenchidos',
    value_attendance_minimum: 'O valor mínimo do atendimento é de R$8,00 (oito reais)',
    wiping_prescription: 'Estamos excluindo essa prescrição... Aguarde.',
    yes: 'Sim',
  },
  pages: {
    appointment: {
      appointment_recurrence: 'Recorrência',
      appointment_time: 'Horário do agendamento',
      calendar: 'Calendário',
      detail: 'Agendamento',
      duration: 'Duração',
      edit: 'Editar Agendamento',
      end: 'Término',
      init: 'Inicio',
      list: 'Agendamentos',
      list_appointments: 'Listagem',
      minutes: 'minutos',
      new: 'Novo Agendamento',
    },
    company: {
      appointment: 'Atendimento',
      appointment_page: {
        allow_professional_change_background: 'Permitir o Profissional mudar a imagem de fundo?',
        default_background_imagem: 'Imagem padrão de fundo',
      },
      basic_data: 'Dados Básicos',
      company: 'Empresa',
      custom_messages: 'Mensagens Personalizadas',
      detail: 'Detalhes',
      edit: 'Editar',
      landing: 'Site',
      landing_page: {
        allow_show_doctors: 'Exibir profissionais no site?',
        enabled: 'Site habilitado?',
        external_page: {
          enabled: 'Página externa habilitada?',
          name: 'Nome da sessão',
          name_hint: 'Nome como aparecerá na sua página',
          title: 'Página externa',
          url: 'URL da página externa',
          url_hint: 'URL da página externa que aparecerá na sua página',
        },
        labels: {
          add_image: 'Adicionar imagem',
          bold: 'Negrito',
          center_text: 'Texto centralizado',
          error_add_image: 'Primeiro adicione uma imagem de quem somos na seção IMAGENS.',
          highlight: 'Marcado',
          horizontal_line: 'Linha horizontal',
          italic: 'Itálico',
          justify_text: 'Texto justificado',
          left_text: 'Texto à esquerda',
          ordered_list: 'Lista ordenada',
          paragraph: 'Parágrafo',
          right_text: 'Texto à direita',
          strike: 'Traçado',
          subtitle: 'Subtítulo',
          title: 'Título',
          unordered_list: 'Lista não ordenada',
        },
        seo: {
          description: 'Descrição',
          description_hint: 'Descrição da sua página que deverá aparecer nas pesquisas do Google',
          meta_tags: 'Palavras chave',
          meta_tags_hint: 'Palavras chave que deverão ser buscadas em pesquisas do Google. (Separadas por vírgula)',
          title: 'SEO',
        },
        show_attendance_value: 'Exibir valor do atendimento?',
        social_media: {
          describe: "Informe somente as URL's que deseja exibir no site",
          facebook: 'Facebook',
          instagram: 'Instagram',
          linkedin: 'LinkedIn',
          telegram: 'Telegram',
          title: 'Redes sociais',
          twitter: 'Twitter',
          whatsapp: 'Whatsapp',
          youtube: 'Youtube',
        },
        who_we_are: 'Quem somos',
      },
      list: 'Empresas',
      new: 'Nova Empresa',
      pictures: 'Imagens',
      screening: 'Triagem',
      shortcuts: 'Atalhos',
      theme: 'Tema',
    },
    consultation: {
      lang: 'pt-BR',
      list: 'Consultas',
    },
    doctor: {
      detail: 'Detalhes do Profissional',
      edit: 'Editar Profissional',
      list: 'Profissionais',
      new: 'Novo Profissional',
    },
    emergency: {
      attendance_now: 'Atendimento Emergencial',
      attendances: 'Atendimentos',
      dashboard: 'Painel',
      date_init: 'Data Início',
      emergency_label: 'Fila Virtual',
      entry_date: 'Data da entrada',
      flow_history_steps: {
        FLOW_FINISHED_BY_MANAGER: 'Finalizado pelo gestor',
        FLOW_ON_ATTENDANCE: 'Em atendimento',
        FLOW_ON_POST_ATTENDANCE: 'Pós atendimento',
        FLOW_PERSON_ARRIVAL: 'Paciente entrou',
        FLOW_PERSON_CHECK_RESOURCES: 'Teste de câmera',
        FLOW_PERSON_COMPLAINT_REPORTED: 'Paciente realizou checkin',
        FLOW_PERSON_FINISH_ATTENDANCE: 'Abandonou Fila',
        FLOW_PERSON_FINISHED_BY_SYSTEM: 'Paciente Inativado',
        FLOW_PERSON_MEASUREMENT: 'Paciente coletando os sinais vitais',
        FLOW_PERSON_ON_END: 'Atendimento finalizado',
        FLOW_PERSON_ON_NPS: 'Paciente em NPS',
        FLOW_PERSON_PAYMENT_APPROVED: 'Pagamento aprovado',
        FLOW_PERSON_PAYMENT_REPROVED: 'Pagamento negado',
        FLOW_PERSON_PERFORMING_PAYMENT: 'Paciente em pagamento',
        FLOW_PERSON_READY_TO_BE_ATTENDED: 'Paciente em pré-atendimento',
        FLOW_PERSON_REPORT_COMPLAINT: 'Paciente no checkin',
        FLOW_PERSON_WAITING_NEXT_GROUP: 'Paciente na fila',
        FLOW_PROFESSIONAL_END_ATTENDANCE: 'Profissional finalizou o atendimento',
        FLOW_PROFESSIONAL_FINISHED_BY_MANAGER: 'Profissional encerrado pelo gestor (reset de fluxo)',
        FLOW_PROFESSIONAL_FINISHED_BY_SYSTEM: 'Profissional Inativado',
        FLOW_PROFESSIONAL_READY_TO_ATTEND: 'Profissional em pré-atendimento',
        IN_ATTENDANCE: '{0} em atendimento',
      },
      flow_steps: {
        FLOW_FINISHED_BY_MANAGER: 'Finalizado pelo gestor',
        FLOW_ON_ATTENDANCE: 'Em atendimento',
        FLOW_ON_POST_ATTENDANCE: 'Pós atendimento',
        FLOW_PERSON_ARRIVAL: 'Paciente entrou',
        FLOW_PERSON_CHECK_RESOURCES: 'Teste de câmera',
        FLOW_PERSON_COMPLAINT_REPORTED: 'Checkin',
        FLOW_PERSON_FINISH_ATTENDANCE: 'Abandonou Fila',
        FLOW_PERSON_FINISHED_BY_SYSTEM: 'Inativado',
        FLOW_PERSON_MEASUREMENT: 'Coleta dos sinais vitais',
        FLOW_PERSON_ON_END: 'Finalizado',
        FLOW_PERSON_ON_NPS: 'Paciente em NPS',
        FLOW_PERSON_PAYMENT_APPROVED: 'Pagamento Aprovado',
        FLOW_PERSON_PAYMENT_REPROVED: 'Pagamento Negado',
        FLOW_PERSON_PERFORMING_PAYMENT: 'Em Pagamento',
        FLOW_PERSON_READY_TO_BE_ATTENDED: 'Pre-atendimento',
        FLOW_PERSON_REPORT_COMPLAINT: 'Checkin',
        FLOW_PERSON_WAITING_NEXT_GROUP: 'Na Fila',
        FLOW_PROFESSIONAL_END_ATTENDANCE: 'Atendimento finalizado',
        FLOW_PROFESSIONAL_FINISHED_BY_MANAGER: 'Profissional encerrado pelo gestor (reset de fluxo)',
        FLOW_PROFESSIONAL_FINISHED_BY_SYSTEM: 'Inativado',
        FLOW_PROFESSIONAL_READY_TO_ATTEND: 'Pre-atendimento',
      },
      group: 'Filas',
      history: 'Histórico',
      ind_pac: 'Status paciente',
      ind_prof: 'Status profissional',
      level: {
        PRIMARY: 'Primeiro Nível',
        SECONDARY: 'Encaminhamento',
      },
      patients_in_attendance: 'Em atendimento',
      patients_in_line: 'Pacientes na fila',
      position: 'Posição',
      professional_reset_successfully: 'O fluxo do profissional foi redefinido com sucesso.',
      professionals: 'Profissionais',
      professionals_in_line: 'Profissionais disponíveis',
      protocol: 'Protocolo',
      protocol_closed_error: 'Houve um erro ao finalizar o protocolo {0}.',
      protocol_closed_successfully: 'O protocolo {0} foi encerrado com sucesso.',
      queue: {
        invalid_edit_queue:
          'Atenção! Para editar esta fila conferir se contém no mínimo uma fila de primeiro nível e habilitada!',
        invalid_remove_queue:
          'Atenção! Para remover esta fila conferir se contém no mínimo uma fila de primeiro nível e habilitada!',
      },
      risk: 'Risco',
      status_virtual_line: 'Status Fila',
      virtual_line: 'Fila Virtual',
    },
    finance: {
      edit: 'Configurações',
      financial: 'Financeiro',
      payments: 'Pagamentos',
    },
    home: 'Página Inicial',
    login: 'login',
    login_page: {
      back: 'Voltar',
      close: 'Fechar',
      continue: 'Continuar',
      forgot_password: 'Esqueceu a senha?',
      forgot_password_message: 'Enviaremos um link de recuperação para o seu usuário',
      log_in: 'Entrar',
      login_to_your_account: 'Entre na sua conta',
      password: 'Senha',
      send_mail: 'Enviar E-mail',
    },
    message: {
      archive: 'Arquivo',
      archivedSuccess: 'Arquivado com sucesso.',
      body: 'Corpo',
      detail: 'Mensagem',
      file_error_send: 'Erro ao enviar o(s) arquivo(s)',
      inbox: 'Caixa de entrada',
      list: 'Mensagens',
      new: 'Nova mensagem',
      new_message: 'Nova mensagem',
      new_message_success: 'Mensagem enviada com sucesso',
      new_response: 'Nova resposta',
      recipient_not_found: 'Destinatário não encontrado',
      reply_message: 'Responder',
      response_success: 'Resposta adicionada com sucesso',
      seen: 'Visualizações',
      subject: 'Assunto',
      unarchivedSuccess: 'Desarquivado com sucesso.',
    },
    pageNotFound: 'Página não encontrada',
    patient: {
      detail: 'Detalhes do Paciente',
      edit: 'Editar Paciente',
      list: 'Pacientes',
      new: 'Novo Paciente',
      remove_with_dependents:
        'Para deletar esse Paciente você precisa remover a relação com dependentes cadastrados no sistema.',
    },
    plan: {
      afterCompletingTheFirstPayment: 'Após concluir o primeiro pagamento',
      atTheSigningOfTheContract: 'Na assinatura do contrato',
      detail: 'Plano',
      edit: 'Editar plano',
      list: 'Planos',
      new: 'Novo plano',
      planWithPatientError:
        'Para deletar esse plano você precisa remover a relação com os pacientes cadastrados no sistema.',
      whenWillSubscriberRegisteredInSystem: 'Em que momento o assinante será cadastrado no sistema ?',
    },
    report: {
      appointment: 'Consultas',
      notifications: 'Notificações',
      protocols: 'Protocolos',
      rating: 'Avaliações',
      term: 'Termos',
      title: 'Relatórios',
    },
    schedule: {
      action: 'Ação',
      allDay: 'Dia Inteiro',
      allows_patient_to_schedule: 'Especificar prazo para disponibilizar agendamento',
      at: 'às',
      availability: 'Disponibilidade',
      blocked: 'Bloqueio de Agenda',
      cancel: 'Cancelar',
      date: 'Data',
      dateEnd: 'Data Fim',
      dateStart: 'Data Início',
      day: 'Dia',
      days: {
        0: 'Domingo',
        1: 'Segunda',
        2: 'Terça',
        3: 'Quarta',
        4: 'Quinta',
        5: 'Sexta',
        6: 'Sábado',
      },
      delete: 'Excluir',
      doctors: 'Profissional',
      errors: {
        already_exists: 'A data inserida já está cadastrada',
        appointments_conflicts: 'Conflitos de Agendamentos',
        greater_limit_days: 'O intervalo de datas não pode ser maior que 35 dias',
        invalid_date: 'A data inserida é inválida',
        invalid_time: 'A hora inserida é inválida',
        less_current_date: 'Por favor, inserir uma data maior que a atual',
        less_selected_start_date: 'Por favor, inserir uma data maior que a data selecionada no calendário',
        less_start_date: 'Por favor, inserir uma data maior ou igual que a data atual',
        less_start_time: 'Por favor, inserir horário maior que a hora início',
        out_of_schedule: 'O horário selecionado está fora da agenda do profissional',
        registered_appointments: 'Atenção! Há agendamentos marcados para esse horário!',
        time_conflict: 'Conflito de horários!',
        time_default: 'Horário inferior ao tempo de consulta padrão',
        wont_be_canceled: 'Não será cancelado nenhum agendamento. Se desejado, isso deve ser feito manualmente.',
      },
      extra: 'Extraordinários',
      fixed: 'Fixos',
      fixed_schedule: 'Agenda Fixa',
      flexible: 'Variáveis',
      holidays: 'Feriados',
      hourEnd: 'Hora Fim',
      hourStart: 'Hora Início',
      ignoreHolidays: 'Ignorar feriados na clínica',
      name: 'Nome',
      new: 'Novo',
      save: 'Salvar',
      schedule_availability: 'Disponibilidade de Agenda',
      search: 'Pesquisar',
      timeAllowed: 'Permitir Paciente Agendar',
      type: 'Tipos',
      verify_date: 'Por favor, verifique o campo "data"',
      verify_fields: 'Por favor, verifique os campos destacados e tente novamente',
      verify_name: 'Por favor, verifique o campo "nome"',
    },
    scheduleTime: 'Horário',
    screening: {
      default_subtype: 'Padrão',
      edit_form: 'Edição de triagem',
      field_model: 'Tipo do campo',
      field_models: {
        CHECK_BOX: 'Caixas Seleção',
        RADIO_BUTTON: 'Múltipla Escolha',
        SELECT: 'Lista Suspensa',
        TEXT_AREA: 'Parágrafo',
        TEXT_FIELD: 'Resposta curta',
      },
      field_title: 'Título da pergunta',
      new_form: 'Criação de triagem',
      no_title_question: 'Pergunta sem título',
      option: 'Opção',
      protocol: {
        aacr: 'AACR - Acolhimento com Avaliação e Classificação de Risco',
        mts: 'Protocolo de Manchester',
      },
      required: 'Obrigatório',
      settings: {
        protocol_risk_classification: 'Protocolo - Classificação de risco',
        use_in_emergency: 'Exibir na fila virtual',
        use_in_self_schedule: 'Exibir no auto agendamento',
        use_in_waiting_room: 'Exibir na sala de espera',
      },
      subtype: 'Fila/Especialidade',
      text_response: 'Resposta em texto',
      type: 'Tipo de Triagem',
    },
    settings: {
      callbacks: {
        apppointment: {
          participantConnected: 'participantConnected - O participante entrou no atendimento',
          participantDisconnected: 'participantDisconnected - O participante saiu do atendimento',
          participantWaitingRoom: 'participantWaitingRoom - O participante entrou na sala de espera',
          sessionFinished: 'sessionFinished - O participante principal encerrou a sessão',
          systemFinished: 'systemFinished - O sistema finalizou a consulta',
        },
        emergency: {
          manager: {
            MANAGER_FINISH_FLOW: 'MANAGER_UPDATE_DASHBOARD - Atualização Dashboard',
            MANAGER_START_FLOW: 'MANAGER_START_FLOW - Fila virtual iniciada',
            MANAGER_UPDATE_DASHBOARD: 'MANAGER_FINISH_FLOW - Finalização manual de protocolo',
          },
          person: {
            PERSON_COMPLAINT_REPORTED: 'PERSON_COMPLAINT_REPORTED - Respondeu anamnese pré-consulta',
            PERSON_ENTER_EMERGENCY: 'PERSON_ENTER_EMERGENCY - Acessou Fila Virtual',
            PERSON_FINISH_ATTENDANCE: 'PERSON_FINISH_ATTENDANCE - Finalizou atendimento',
            PERSON_FINISH_BY_SYSTEM: 'PERSON_FINISH_BY_SYSTEM - Atendimento finalzado pelo sistema',
            PERSON_FORWARD_ATTENDANCE: 'PERSON_FORWARD_ATTENDANCE - Encaminhado para outra fila',
            PERSON_LEAVE_EMERGENCY: 'PERSON_LEAVE_EMERGENCY - Saiu da fila',
            PERSON_PLACE_IN_LINE: 'PERSON_PLACE_IN_LINE - Atualizou posição na fila',
            PERSON_READY_TO_ATTENDANCE: 'PERSON_READY_TO_ATTENDANCE - Entrou na sala de pré-atendimento',
            PERSON_START_ATTENDANCE: 'PERSON_START_ATTENDANCE - Iniciou atendimento',
          },
          professional: {
            PROFESSIONAL_ASK_NEXT: 'PROFESSIONAL_ASK_NEXT - Proximo paciente',
            PROFESSIONAL_FINISH_ATTENDANCE: 'PROFESSIONAL_FINISH_ATTENDANCE - Finalizou atendimento',
            PROFESSIONAL_FINISH_BY_SYSTEM: 'PROFESSIONAL_FINISH_BY_SYSTEM - Atendimento finalizado pelo sistema',
            PROFESSIONAL_LEAVE_EMERGENCY: 'PROFESSIONAL_LEAVE_EMERGENCY - Saiu da fila',
            PROFESSIONAL_READY_TO_ATTENDANCE: 'PROFESSIONAL_READY_TO_ATTENDANCE - Entrou na sala de pré-atendimento',
            PROFESSIONAL_REPORT_CHECKIN: 'PROFESSIONAL_REPORT_CHECKIN - Checkin na Fila',
            PROFESSIONAL_START_ATTENDANCE: 'PROFESSIONAL_START_ATTENDANCE - Iniciou atendimento',
          },
        },
        title: {
          event_appointment: 'Eventos de Atendimento',
          event_appointment_manager: 'Eventos de Fila Virtual - Gestor',
          event_appointment_patient: 'Eventos de Fila Virtual - Paciente',
          event_appointment_professional: 'Eventos de Fila Virtual - Profissional',
        },
      },
      company: 'Empresa',
      custom_messages: 'Mensagens',
      developer: 'Desenvolvedor',
      edit: 'Configurações',
      general: 'Gerais',
      term: 'Termos',
    },
    term: {
      ask_all_attendances: 'Solicitar em todos atendimentos',
      description: 'Descrição',
      disabled: 'Desabilitado',
      enabled: 'Habilitado',
      last_update: 'Última Atualização',
      require_whole_attendances: 'Solicitar em todas consultas',
      term_use: 'Uso do termo',
      where_to_use: {
        ALL: 'Todos',
        ATTENDANCE: 'Atendimento',
        EMERGENCY: 'Fila Virtual',
        label: 'Onde Usar',
        LOGIN: 'Login',
      },
    },
    user: {
      detail: 'Detalhes',
      edit: 'Editar',
      list: 'Usuários',
      managedProfessionals: 'Profissionais gerenciados pelo usuário',
      manageProfessional: 'Adicionar Profissionais gerenciados pelo usuário',
      new: 'Novo Usuário',
    },
    voucher: {
      detail: 'Voucher',
      edit: 'Editar Voucher',
      list: 'Cupons',
      new: 'Novo Voucher',
    },
  },
  template_mail: {
    APPOINTMENT_CONFIRM_PERSON: 'Confirmação de Agendamento',
    APPOINTMENT_CONFIRM_PERSON_SMS: 'Confirmação de Agendamento',
    APPOINTMENT_CONFIRM_PERSON_WHATSAPP: 'Confirmação de Agendamento',
    APPOINTMENT_CONFIRMED_SMS: 'Novo Agendamento',
    APPOINTMENT_CONFIRMED_WHATSAPP: 'Novo Agendamento',
    APPOINTMENT_EMERGENCY_CONFIRMED_WHATSAPP: 'Confirmação de agendamento - Pronto-Atendimento',
    APPOINTMENT_NO_CHARGED_PATIENT: 'Novo Agendamento',
    APPOINTMENT_NO_CHARGED_PROFESSIONAL: 'Novo Agendamento',
    APPOINTMENT_PAYMENT_APPROVED_COMPANY: 'Pagamento de atendimento',
    APPOINTMENT_PAYMENT_APPROVED_PERSON: 'Agendamento pago confirmado ',
    APPOINTMENT_PAYMENT_APPROVED_PROFESSIONAL: ' Agendamento pago confirmado ',
    APPOINTMENT_PAYMENT_PENDING_PATIENT: 'Agendamento pendente de pagamento',
    APPOINTMENT_PAYMENT_PENDING_PATIENT_PAYER: 'Cobrança de agendamento ',
    APPOINTMENT_PAYMENT_PENDING_PROFESSIONAL: 'Agendamento pendente de pagamento',
    APPOINTMENT_PAYMENT_PENDING_PROFESSIONAL_PAYER: 'Cobrança de agendamento',
    APPOINTMENT_PAYMENT_PENDING_RESPONSIBLE: 'Cobrança de agendamento',
    APPOINTMENT_PAYMENT_PENDING_SMS: 'Cobrança de agendamento',
    APPOINTMENT_PAYMENT_PENDING_WHATSAPP: 'Cobrança de agendamento',
    APPOINTMENT_PAYMENT_REPROVED_COMPANY: 'Agendamento com pagamento reprovado',
    APPOINTMENT_POST_ATTENDANCE_PERSON: 'Pós atendimento',
    APPOINTMENT_RECURRENCE_PERSON: 'Novo agendamento com recorrência',
    APPOINTMENT_REMOVED_PERSON: 'Removido de agendamento',
    APPOINTMENT_REMOVED_PROFESSIONAL: 'Removido de agendamento',
    BENEFITCLUB_ACTION_FAILED: 'Falha na integração do clube de benefícios',
    BENEFITCLUB_ADD_CUSTOMER: 'Novo usuário do clube de benefícios',
    EMERGENCY_NEW_PERSON: 'Acesso a Fila Virtual',
    EMERGENCY_PAYMENT_APPROVED_COMPANY: 'Pagamento de Fila Virtual',
    EMERGENCY_PAYMENT_APPROVED_PATIENT: 'Pagamento de Fila Virtual',
    FINANCIAL_PROVIDER_CNPJ_PLAN: 'Novo contrato de Plano Empresarial',
    GALAXY_WEBHOOK_ACTION_FAILED: 'Aviso de falha na integração',
    NEW_DIAGNOSTIC_EMAIL_NOTIFICATION: 'Novo prontuário médico',
    NEW_MESSAGE_NOTIFICATION_EMAIL: 'Novo lembrete de mensagem',
    NOTIFICATION_REMINDER_EMAIL: 'Lembrete de agendamento',
    NOTIFICATION_REMINDER_SMS: 'Lembrete de agendamento',
    NOTIFICATION_REMINDER_WHATSAPP: 'Lembrete de agendamento',
    NPS_EMERGENCY_SERVICE_MAIL: 'Avaliação de atendimento na Fila Virtual',
    NPS_SERVICE_MAIL: 'Avaliação de atendimento',
    PLAN_ACTIVE_STATUS_NOTIFICATION: 'Alteração do status de plano para ativo',
    PLAN_BLOCK_STATUS_NOTIFICATION: 'Alteração do status de plano para bloqueado',
    PLANCHECK_SCHEDULE_NOTIFICATION: 'Alteração de status dos pacientes',
    USER_CREATED_COMPANY: 'Novo usuário empresa',
    USER_CREATED_PERSON: 'Novo usuário',
    USER_CREATED_PERSON_NOTIFY_COMPANY: 'Novo usuário',
    USER_CREATED_PROFESSIONAL: 'Novo usuário',
    USER_CREATED_PROFESSIONAL_DOCTOR: 'Novo usuário',
    USER_FORGOT_PASSWORD_PERSON: 'Esqueceu senha',
    USER_FORGOT_PASSWORD_PROFESSIONAL: 'Esqueceu senha',
    USER_FORGOT_PASSWORD_PROFESSIONAL_COMPANY: 'Esqueceu senha',
    USER_UPDATED_PERSON_NEW_LOGIN: 'Atualização de login',
    USER_UPDATED_PERSON_OLD_LOGIN: 'Atualização de login',
    USER_UPDATED_PROFESSIONAL_DC_NEW_LOGIN: 'Atualização de login',
    USER_UPDATED_PROFESSIONAL_DC_OLD_LOGIN: 'Atualização de login',
    USER_UPDATED_PROFESSIONAL_NEW_LOGIN: 'Atualização de login',
    USER_UPDATED_PROFESSIONAL_OLD_LOGIN: 'Atualização de login',
    VOUCHER_PARTICIPANT_NOTIFICATION: 'Voucher',
    VOUCHER_PARTICIPANT_NOTIFICATION_SMS: 'Voucher',
    VOUCHER_PARTICIPANT_NOTIFICATION_WHATSAPP: 'Voucher',
  },
  title: {
    appointment: {
      companion_id: 'Acompanhante',
      description: 'Descrição do Atendimento',
      doctor: 'Profissionais',
      doctor_id: 'Profissional',
      end_date: 'Data Término',
      end_date_time: 'Data / Hora de término do Atendimento',
      end_time: 'Hora Término',
      medical_assistent: 'Profissional Assistente',
      notes: 'Notas da Consulta',
      patient_id: 'Paciente',
      role: 'Tipo Participante',
      start_date: 'Data Início',
      start_date_time: 'Data / Hora de início do Atendimento',
      start_time: 'Hora Início',
      status_appointment: 'Status de Agendamento',
      title: 'Título do Atendimento',
      type: 'Tipo de Atendimento',
      upload_image: 'Upload de imagem',
    },
    company: {
      birth_date: 'Data de Nascimento',
      business_contact: 'Contato de négocio',
      cell_phone: 'Celular',
      city: 'Cidade',
      cnpj: 'CNPJ',
      comission_type: 'Tipo de comissão',
      comission_value: 'Valor de comissão',
      company_id: 'Empresa',
      complement: 'Complemento',
      cpf: 'CPF',
      edit: 'Editar',
      expiration_date: 'Data de Validade',
      foto: 'Foto',
      gender: 'Sexo',
      mail: 'E-mail',
      name: 'Nome Fantasia',
      neighborhood: 'Bairro',
      number: 'Número',
      parent_id: 'Paciente responsável financeiramente pelo paciente',
      plan_id: 'ID plano',
      region: 'Unidade Federal',
      representative_id: 'ID representante',
      social_name: 'Razão Social',
      status: 'Status',
      street: 'Rua, Avenida...',
      telephone: 'Telefone',
      zip_code: 'CEP',
    },
    doctor: {
      birth_date: 'Data de Nascimento',
      cell_phone: 'Celular',
      city: 'Cidade',
      company_id: 'Empresa',
      complement: 'Complemento',
      cpf: 'CPF',
      Filtro: 'Filtro',
      gender: 'Sexo',
      id: 'Identificador',
      mail: 'E-mail',
      medical_specialty: 'Especialidade do Profissional',
      name: 'Nome',
      neighborhood: 'Bairro',
      number: 'Número',
      picture: 'Foto',
      professional_registration: 'Registro Profissional',
      region: 'Unidade Federal',
      registration_state: 'UF do registro',
      status: 'Status',
      street: 'Rua, Avenida...',
      telephone: 'Telefone',
      zip_code: 'CEP',
    },
    patient: {
      address: 'Endereço',
      birth_date: 'Data de Nascimento',
      cell_phone: 'Celular',
      city: 'Cidade',
      company_id: 'Empresa',
      complement: 'Complemento',
      country: 'País',
      cpf: 'CPF',
      dependent_limit: 'Limite de Dependentes',
      gender: 'Sexo',
      mail: 'E-mail',
      name: 'Nome',
      neighborhood: 'Bairro',
      number: 'Numero',
      parent_id: 'Paciente responsável financeiramente pelo paciente',
      picture: 'Foto',
      plan: 'Plano',
      profession: 'Profissão do paciente',
      region: 'Região',
      status: 'Status',
      status_plan: 'Status do plano',
      street: 'Rua Avenida...',
      telephone: 'Telefone',
      zip_code: 'CEP',
    },
    plan: {
      additional_minute_recorded_allowed: 'Indica se esse plano permite minuto adicional para gravação',
      additional_minute_value: 'Valor do minuto adicional de consulta do plano',
      additional_minutes_allowed: 'Indica se esse plano permite minuto adicional para consulta',
      additional_recorded_minute_value: 'Valor do minuto adicional de gravação do plano',
      attendance_average_minutes: 'Quantidade média de minutos de consulta para apuração de contrato',
      attendance_average_minutes_recorded: 'Quantidade média de minutos de gravação para apuração de contrato',
      attendance_value: 'Valor da Consulta',
      bill_type: 'Tipo do plano',
      commission_type: 'Tipo da Comissão',
      commission_value: 'Valor da Comissão',
      corporate_document_number: 'Número do documento',
      corporate_plan: 'Plano empresarial?',
      description: 'Nome do Plano',
      duration_days: 'Indica a quantidade de dias de duração do plano',
      initial_attendance_quantity: 'Quantidade inicial de consultas para essa faixa',
      max_additional_minute: 'Quantidade máxima de minutos adicionais para consulta',
      max_additional_recorded_minute: 'Quantidade máxima de minutos adicionais para gravação',
      max_attendance_minutes: 'Indica a duração máxima de minutos da consulta',
      minutes_quantity: 'Quantidade de minutos de consulta do plano',
      minutes_value: 'Valor do minuto de consulta do plano',
      name: 'Nome do Plano',
      recorded_minute_value: 'Valor do minuto de gravação do plano',
      recorded_minutes_quantity: 'Quantidade de minutos de gravação do plano',
      with_benefitclub_offer: 'Plano com oferta de clube de benefícios?',
      with_secure_offer: 'Plano com oferta de seguro?',
    },
    settings: {
      allow_access_appointment_before: 'Permitirá o acesso à sala de consulta antes do horário determinado para início',
      allows_patient_to_schedule: 'Permitirá o paciente realizar agendamento da consulta',
      allows_patient_to_schedule_a_return_visit: 'Permitir paciente solicitar retorno de consultas agendadas',
      allows_patient_to_schedule_time_after: 'Quantidade de dias máximo para agendamento no futuro',
      allows_patient_to_schedule_time_before:
        'Tempo permitido para o paciente realizar agendamento antes do horário disponíbilizado',
      appointment_duration: 'Tempo médio da consulta em minutos',
      callback_url_for_query_notifications: 'URL de callback para notificações da consulta',
      color_primary: 'Define a cor primária da aplicação, Ex: TOPO e Botões',
      deadline_to_return: 'Prazo para solicitação de retorno',
      deny_access_appointment_after:
        'Ao exceder o tempo configurado após o início da consulta, nenhuma acesso na mesma será permitido',
      ended_appointment_after_duration:
        'Define se após o tempo máximo da consulta estiver terminado, a mesma deve ser encerrada automaticamente',
      finance_mail_address: 'E-mail para contato. Ex: contato@clinica.com',
      hour: 'Define a hora que será enviada a notificação antes do horário marcado da consulta',
      intermediary_account_number: 'Intermediary account number',
      max_appointment_duration:
        'Tempo máximo da consulta em minutos, caso o tempo seja atingido a consulta é finalizada',
      minutes: 'Define os minutos que será enviada a notificação antes do horário marcado da consulta',
      module_used: 'Criar usuário/login no sistema ao cadastrar profissional/paciente?',
      period: 'Período',
      restore_color: 'Restaurar cor padrão',
      select_color: 'Personalizar com a cor de sua preferência',
      show_countdown_timer: 'Quando estiver faltando X minutos o contador regressivo é exibido para o profissional',
      time_inactivity_to_disconnect: 'Tempo de inatividade em minutos para desconectar do atendimento',
    },
    user: {
      company_id: 'Empresa',
      mail: 'E-mail',
      name: 'Nome',
      namecpf: 'Nome | Cpf',
    },
  },
  value: {
    active: 'Ativo',
    blocked: 'Bloqueado',
    inactive: 'Inativo',
    no: 'Não',
    yes: 'Sim',
  },
};

export default ptBR;
